@import 'src/Styles/Constants';


.container {
  margin-bottom: 20px;
  background-color: #F4F4F4;
  border: 1px solid #EADEDE;
  border-radius: 5px;
  padding: 30px;

  display: grid;
  grid-template-columns: auto 1fr 1fr auto;
  gap: 20px;
}

.header {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: rgba(109, 77, 77, 0.3);
  margin-bottom: 10px;
}

.title {
  font-family: "Cardo", serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 0;
}

.subtitle {
  padding: 2px 0;
  font-size: 16px;
  color: #6D4D4D;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}