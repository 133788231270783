@import "src/Styles/Constants";

$transition: all 150ms ease-in-out;

.input {
  font-family: "Open Sans", sans-serif;

  line-height: 2.7rem;
  min-height: 2.7rem;

  padding: 0 15px;
  color: $textColor;
  border-radius: 3px;
  background-color: #FCFCFC;

  display: inline-block;
  outline: 0;
  border: 1px solid $borderColor;
  overflow: hidden;

  width: 100%;

  &:disabled {
    cursor: not-allowed;
    background-color: #f1f3f6;
  }

}

.container {
  position: relative;

  width: 100%;
  cursor: pointer;
}

.clear {
  position: absolute;
  width: 10%;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $red;
}

.head_cell {
  > * {
    color: $textColor;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.caption_label {
  text-transform: uppercase;
  font-weight: 600;
  color: $textColor;
}

.day {
  width: 40px;
  height: 40px;
  cursor: pointer;

  &:hover:not(.daySelected) {
    color: black;
    background-color: #fae9e9;
    border-radius: 40px;

    > * {
      font-weight: 600;
    }
  }

}

.button {
  outline: none;
  border: none;
  background-color: transparent;
}

.daySelected {
  background-color: #fae9e9;
  color: black;
  border-radius: 40px;

  > * {
    font-weight: 600;
  }
}

.months {
  display: flex;;

  > .month:not(:last-child) {
    padding-right: 13px;
    border-right: 2px solid rgba(178, 183, 192, 0.5);
  }

  > .month:not(:first-child) {
    padding-left: 13px;
  }
}
