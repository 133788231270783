.header {
  width: 100%;
  display: flex;
  padding: 25px 40px;
  gap: 10px;
  align-items: center;
}

.titleContainer {
  height: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 26px;
  font-family: "Cardo", sans-serif;
  text-transform: uppercase;
  color: #707070;
  font-weight: 800;
  line-height: .7;
}

.subTitle {
  color: #707070;
}

.headerContent {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}