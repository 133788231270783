@import 'src/Styles/Constants';

.imageRow {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 30px;
}

.fileUpload {
    background-color: #EAEAEC;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $borderColor;
}
