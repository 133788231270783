@import "src/Styles/Constants";

$transition: all 150ms ease-in-out;


.trigger {
  position: relative;
  background-color: #FCFCFC;
  border: 1px solid $borderColor;
  border-radius: 3px;
  line-height: 2.7rem;
  min-height: 2.7rem;
  display: flex;
  align-items: flex-end;
  width: 100%;

  &.active {
    border-radius: 3px 3px 0 0;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: #f1f3f6;
  }

  &.error {
    border: 1px solid #EF2222;
  }
}

.label {
  pointer-events: none;
  color: #6D4D4D;
  font-family: Cardo, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.fieldActive {
  transform: translateY(-8px);
  font-size: 10px;
}

.floatingLabel {
  -webkit-appearance: none !important;
}

.value {
  line-height: 2.7rem;
  min-height: 2.7rem;

  padding: 0 15px;
  color: $textColor;
  font-size: 0.9rem;
  font-weight: 500;

  display: inline-block;
  outline: 0;
  border: none;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  width: 100%;

  &.disabled {
    color: #9AAAC9;
  }
}

.input {
  font-family: krub, sans-serif;

  line-height: 2.7rem;
  min-height: 2.7rem;

  padding: 0 15px;
  color: $textColor;
  font-weight: 500;
  font-size: 0.9rem;

  display: inline-block;
  outline: 0;
  border: none;
  background-color: transparent;
  overflow: hidden;

  width: 100%;
}

.input::placeholder {
  color: transparent;
}

.container {
  position: relative;
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
}

.dropdown {
  display: none;

  position: absolute;

  top: 2.8rem;
  left: 0;
  width: 100%;
  transition: $transition;

  background-color: #FCFCFC;
  border-width: 0 1px 1px 1px;
  border-color: $borderColor;
  border-style: solid;
  border-radius: 0 0 3px 3px;

  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);

  opacity: 0;

  overflow: auto;
  max-height: 300px;

  z-index: 99999;

  &.open {
    display: block;
    opacity: 1;
  }

  &.closed {
    opacity: 0;
  }
}

.hidden {
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
}

.valueContainer {
  padding-right: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.clear {
  margin-left: 10px;
  margin-top: 9px;
}

.noResults {
  text-align: center;
  padding: 10px;
}