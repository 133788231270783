$borderRadius: 3px;
$defaultBorderColor: #EADEDE;
$defaultBorderWidth: 1px;
$columnBorder: 1px solid $defaultBorderColor;


.container {
  width: 100%;
  display: grid;

  grid-auto-rows: min-content;
}

.title {
  color: rgba(109, 77, 77, 0.6);
  font-weight: bold;
  font-size: .9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 10px;
  gap: 5px;
}

.row {
  display: contents;
}

.column {
  position: relative;
  background-color: white;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  padding: 10px 0;

  margin: 5px 0;

  border-style: solid ;
  border-width: 0;
  border-top-width: 1px;
  border-bottom-width: $defaultBorderWidth;
  border-color: $defaultBorderColor;


  &:first-child {
    padding-left: clamp(8px, 1vw, 20px);
    padding-right: clamp(8px, 1vw, 20px);
    border-left-width: $defaultBorderWidth;
    border-radius: $borderRadius 0 0 $borderRadius;
  }

  &.image {
    padding: 0;
  }

  &:last-child {
    border-right-width: $defaultBorderWidth;
    border-radius: 0 $borderRadius $borderRadius 0;
  }
}

.rowImage {
  border-radius: 2px;
  margin-right: 10px;
  height: 50px;
  overflow: hidden;
}

.img {
  aspect-ratio: 1 / 1;
  max-width: 50px;
  max-height: 50px;
  border-radius: 2px;
}

.separator {
  height: 100%;
  padding: 0 clamp(8px, 1vw, 20px);
  border-left: 1px solid $defaultBorderColor;
}

.selector {
  opacity: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.15s;
  cursor: pointer;
  
  &.visible, &:hover {
    opacity: 1;
  }
}