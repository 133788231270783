@import "src/Styles/Constants";

$size: 20px;

.bullet {
  height: $size;
  width: $size;
  border-radius: $size;
  border: 1px solid #6D4D4D;

  margin-right: 10px;

  &.green {
    background-color: $green;
  }

  &.red {
      background-color: $red;
  }

  &.yellow {
      background-color: $yellow;
  }

  &.grey {
    background-color: $grey;
  }

  &.blue {
    background-color: $blue;
  }

  &.purple {
    background-color: $purple;
  }
}