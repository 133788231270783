@import 'src/Styles/Constants';

.imageRow {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 30px;
}

.fileUpload {
  background-color: #EAEAEC;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $borderColor;
}

.contactContainer {
  background-color: $backgroundColor;
  border: 1px solid $borderColor;
  padding: 10px;
  border-radius: 3px;
  display: grid;
  gap: 20px;
  position: relative;
}

.delete {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}