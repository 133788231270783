.container {
  display: grid;
  height: 100%;
  grid-template-rows: 110px 1fr;
}

.content {
  height: 100%;
  border-top: 1px solid #E0E0E0;
  background: linear-gradient(135deg, #FFFFFF 0%, #EFEFEF 100%);
  padding: 0 40px;
  padding-bottom: 300px;

  &.padding {
    padding: 20px 40px;
  }
}
