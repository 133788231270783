.container {
  padding: 0 0 0 15px
}

.label {
  display: flex;
  align-items: center;
  padding: 7px 0;

  .icon {
    padding-right: 7px;
  }

  .text {
    color: #C0CADD;
    font-weight: 600;
    font-size: 12px;
  }
}

.content {
  margin-left: 5px;
  border-left: 3px solid #E9F1FF;
}
