.root {
  background-color: #EADEDE;
}
.root[data-orientation='horizontal'] {
  height: 1px;
  width: 100%;
}
.root[data-orientation='vertical'] {
  height: 100%;
  width: 1px;
}
