.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;

  > :first-child {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 30px;
  }
}

.details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.customerName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 22px;
  font-family: "Cardo", serif;
  font-weight: bold;
}

.detailsHeader {
  font-family: "Cardo", serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 5px;

  &.padding {
    margin-bottom: 20px;
  }
}

.commentHeader {
  font-family: "Cardo", serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #F4F4F4;
  border: 1px solid #F3EBEB;
  min-height: 100px;
}

.addressDetails {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.centerHorizontal {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #CCC3C3;
  margin-bottom: 5px;
}
