.sectionTitle {
  font-family: "Cardo", serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.invoiceLine {
  background-color: #F4F4F4;
  border: 1px solid #EADEDE;
  border-radius: 5px;
  padding: 5px 15px;
  margin-bottom: 10px
}