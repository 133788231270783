@import 'src/Styles/Constants';

.totalContainer {
  display: flex;
  border: 1px solid $borderColor;
  border-radius: 3px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px 20px;

  gap: 20px;

  > div {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }
}

.header {
  font-size: 14px;
}

.total {
  font-size: 18px;
  font-weight: 800;
}

.first {
  border-radius: 3px 0 0 3px;
}

.last {
  background-color: $red;
  color: white;
  border-radius: 0 3px 3px 0;
}