$small: 4px;
$medium: 8px;
$large: 12px;

.container {
  &-horizontal {
    display: flex;
  }
}

@mixin padding-horizontal($size) {
  flex: 1;
  padding: 0 $size;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
      padding-right: 0;
  }
}

@mixin padding-vertical($size) {
  padding: $size 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.space-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-horizontal {

  &-small {
    @include padding-horizontal($small);
  }

  &-medium {
    @include padding-horizontal($medium);
  }

  &-large {
    @include padding-horizontal($large);
  }
}

.space-vertical {
  &-small {
    @include padding-vertical($small);
  }

  &-medium {
    @include padding-vertical($medium);
  }

  &-large {
    @include padding-vertical($large);
  }
}
