@import 'src/Styles/Constants';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($textColor, 0.8);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  transition: all 0.15s ease-in-out;
  pointer-events: none;
  opacity: 0;

  &.enterDone {
    opacity: 1;
    pointer-events: visible;
  }

  &.exit {
    opacity: 0;
  }

  > .content {
    margin-top: 30px;
    width: 600px;
    max-height: 90vh;
    overflow: scroll;

    background-color: white;
    transition: all 0.15s ease-in-out;
    transform: none;
  }
}

.header {
  padding: 30px 30px 0 30px;

  > span {
    font-size: 30px;
    text-align: center;
  }

  display: flex;
}

.body {
  padding: 20px 40px 40px 40px;
}
