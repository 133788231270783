@import "src/Styles/Constants";

$margin: 40px;

.layout {
  background: linear-gradient(135deg, #FFFFFF 0%, #EFEFEF 100%);
}

.container {
  margin-bottom: 100px;
  background-color: #FFFFFF;
  max-width: $defaultMaxWidth;
  border-color: #EADEDE;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  padding: 50px;
  margin-left: $margin;
  display: grid;
}

.content {
  display: grid;
  gap: 20px;

  grid-template-columns: calc($defaultMaxWidth + $margin) 1fr;
  padding-right: 40px;
}

.sectionTitle {
  font-family: "Cardo", serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.outsourcingPartnerHeader {
  font-family: "Cardo", serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  margin: 10px 0;
}

.total {
  margin-bottom: 20px;
  background-color: #F4F4F4;
  border: 1px solid #EADEDE;
  border-radius: 5px;
  padding: 30px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  
  :last-child {
    font-weight: bold;
  }
}