.flex {
  display: flex;

  &.justify-center {
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }

  &.column {
    flex-direction: column;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.w-100 {
  width: 100%
}

.h-100 {
  height: 100%
}
