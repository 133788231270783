.container {
  width: 100%;
  background-color: #DCDCDC;
  height: 10px;
  position: relative;
}

.progressMarker {
  position: absolute;
  top: 0;
  width: 100%;
  height: 10px;
  display: flex;
  
  > div {
    height: 10px;
    border-right: 1px solid black;
  }
}

.progress {
  width: 0;
  transition: all 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  height: 100%;
}