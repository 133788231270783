.sectionTitle {
  font-family: "Cardo", serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.outsourcingPartnerHeader {
  font-family: "Cardo", serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  margin: 10px 0;
}