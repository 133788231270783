@import "src/Styles/Constants";

$gap: 20px;

.threeColumns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $gap;
}

.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $gap;
}

.orderLineContainer {
  background-color: $backgroundColor;
  border: 1px solid $borderColor;
  padding: 10px;
  border-radius: 3px;
  display: grid;
  gap: 20px;
  position: relative;
}

.delete {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.inStock {
  padding-top: 4px;
  color: $green;
}

.lowStock {
  padding-top: 4px;
  color: $red;
}