@import "src/Styles/Constants";

.label {
  pointer-events: none;
  color: #6D4D4D;
  font-family: Cardo, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.container {
  position: relative;
}

.input {
  font-family: "Open Sans", sans-serif;

  line-height: 2.7rem;
  min-height: 2.7rem;

  padding: 0 15px;
  color: $textColor;
  border-radius: 3px;
  background-color: #FCFCFC;

  display: inline-block;
  outline: 0;
  border: 1px solid $borderColor;
  overflow: hidden;

  width: 100%;

  &:disabled {
    cursor: not-allowed;
    background-color: #f1f3f6;
  }

  &.error {
    border: 1px solid #EF2222;
  }
}

.prefix {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
  font-weight: 600;
  color: $textColor;
}


.suffix {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
  font-weight: 600;
  color: $textColor;
}
