@import "src/Styles/Constants";

.imageRow {
  display: grid;
  grid-template-columns: repeat(5, 150px);
  gap: 30px;
}

.mockupFileContainer {
  background-color: $backgroundColor;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $borderColor;
  
  &.dragging {
    border: 2px dashed darken($borderColor, 20%);
  }
}

.printsContainer {
  background-color: #F4F4F4;
  border: 1px solid $borderColor;
  padding: 30px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: auto 1fr 1fr auto;
  gap: 20px;
  position: relative;
}

.relative {
  position: relative;
}

.delete {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}