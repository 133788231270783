@import 'src/Styles/Constants';

.container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  max-width: 500px;
  gap: 20px;
  overflow: scroll;
}

.pageItem {
  border: 1px solid $borderColor;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;

  border-radius: 3px;
  cursor: pointer;

  &.active {
    border-width: 2px;
    background-color: $backgroundColor;
  }
}