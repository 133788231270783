$border-radius: 3px;

.button {
  -moz-border-radius: $border-radius;
  border-radius: $border-radius;
  font-size: 1em;
  text-align: center;
  height: 45px;
  cursor: pointer;
  padding: 0 20px;
  outline: none;
  white-space: nowrap;
  display: flex;

  justify-content: center;
  border: 0;
  transition: all .2s ease-out;

  > * {
    line-height: 45px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    font-family: "Open Sans", sans-serif!important;
  }

  &.primary {
    background-color: #E91D25;
    color: #FFF;

    box-shadow: -7px 7px #333333;

    &:hover {
      background-color: darken(#E91D25, 5%);
      box-shadow: -3px 3px #333333;
    }
  }

  &.secondary {
    background-color: #151516;
    color: #FFF;

    &:hover {
      background-color: lighten(#151516, 15%);
    }
  }

  &.tertiary {
    background-color: #B4B4B4;
    color: #FFF;

    &:hover {
      background-color: darken(#B4B4B4, 10%);
    }
  }
}

