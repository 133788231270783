@import "src/Styles/Constants";

$transition: all 150ms ease-in-out;

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $textColor;
  font-size: 16px;
  font-weight: 500;

  height: 32px;
  padding: 5px 15px;

  transition: $transition;

  cursor: pointer;

  &:hover, &.selected {
    background-color: #fae9e9;
    font-weight: 600;
    color: $textColor;
  }
}
