.container {
  display: grid;
  grid-template-columns: 70px auto auto;
  align-items: center;
  background-color: #F4F4F4;
  border-radius: 3px;
  border: 1px solid #E0E0E0;
  cursor: pointer;
  overflow: hidden;

  > div {
    height: 100%;
    padding: 20px;
  }

  > :first-child, > :last-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #707070;
}

.subTitle {
  color: #B5B5B5;
}