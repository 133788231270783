.container {
  background-color: #F4F4F4;
  border: 1px solid #EADEDE;
  border-radius: 5px;
  padding: 30px;

  display: grid;
  grid-template-columns: auto 1fr 1fr auto;
  gap: 20px;
}

.projectImage {
  background-color: #F4F4F4;
  border: 1px solid #F3EBEB;
  height: 100px;
  width: 100px;
  aspect-ratio: 1;
}

.header {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: rgba(109, 77, 77, 0.3);
  margin-bottom: 10px;
}

.projectName {
  font-family: "Cardo", serif;
  font-weight: bold;
  font-size: 22px;
}

.centerHorizontal {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #CCC3C3;
  margin-bottom: 5px;
}
