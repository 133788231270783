$border: 1px solid #E4E8F0;;

.container {
  position: relative;
  display: flex;
  width: 100%;
}

.dropdown {
  position: absolute;
  display: block;

  min-width: 200px;
  background-color: #fff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  white-space: nowrap;
  opacity: 0;
  transform: translateY(-2px);
  transition: opacity 0.09s ease-out, transform 0.12s ease-out;

  pointer-events: none;

  z-index: 99999;

  border: $border;

  &.open {
    opacity: 1;
    pointer-events: all;
  }
}

.pointer {
  content: "";
  display: block;
  position: absolute;
  border-top: $border;
  border-left: $border;
  border-top-left-radius: 2px;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#050505+0,ffffff+54 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  rgba(255, 255, 255, 0.5) 0%, #ffffff 54%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  rgba(255, 255, 255, 0.5) 0%,#ffffff 54%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  rgba(255, 255, 255, 0.5) 0%,#ffffff 54%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#050505', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}
