@import "src/Styles/Constants";

.container {
  background-color: #FFFFFF;
  max-width: $defaultMaxWidth;
  border-color: #EADEDE;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  padding: 50px;
}
