.layout {
    display: grid;
    grid-template-columns: 0 auto;
    grid-template-rows: 1fr;
    height: 100vh;

    transition: all .4s ease-in-out;

    &.open {
      grid-template-columns: clamp(200px, 15vw, 250px) auto;
    }
}

.menu {
  background-color: #151515;
  box-shadow: 6px 0 20px rgba(0, 0, 0, .15);
  z-index: 1;

  width: 100%;
  display: grid;
  grid-template-rows: 110px auto min-content;
  position: relative;

  > * {
    overflow: hidden;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle {
  position: absolute;
  right: -20px;
  top: 40px;
  z-index: 9999;
  background-color: #151515;
  width: 20px;
  height: 30px;
  padding: 3px 3px 3px 2px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;

  > * {
    transition: all .2s ease-in-out;
  }

}

.content {
  background-color: #EFEFEF;
  min-height: 100vh;;
  overflow: scroll;
}

.menuItem {
  width: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  height: 45px;
  padding-left: 20px;
  text-decoration: none;
  color: #707070;
  text-transform: uppercase;
  position: relative;

  &.active {
    background-color: #FFFFFF;
    color: #E91D25;
    font-weight: 600;
  }

  > .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
  }
}

.activeIcon {
  position: absolute;
  right: 0;
  top: 5px;
}
