
$borderColor: #EADEDE;
$textColor: #6D4D4D;

$green: #21BF2D;
$red: #E91D25;
$yellow: #FFBA00;
$grey: #42413f;
$blue: #0048ff;
$purple: #7F00FF;

$backgroundColor: #EAEAEC;

$defaultMaxWidth: 990px;
